import { useState, useRef, useEffect } from 'react'
import { useFormikContext, useField } from 'formik'
import { useMediaQuery } from '@react-hookz/web/esnext'
import { default as countries } from './countries'
import { default as Flag, getFlagEmoji } from './Flag'
import classnames from 'classnames/bind'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function PhoneInput({ innerRef, onSubmit }) {
  const wrapperRef = useRef()
  const listRef = useRef()
  const inputRef = innerRef || useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [renderAbove, setRenderAbove] = useState(false)
  const isMobile = useMediaQuery('only screen and (max-width: 969px)')
  const { values, setFieldValue } = useFormikContext()
  const [inputField] = useField({ name: 'phone' })

  const toggleOpen = () => {
    if (!isOpen && wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - (rect.y + rect.height)
      setRenderAbove(spaceBelow < rect.y)
    }
    setIsOpen(!isOpen)
  }

  const selectItem = item => {
    setIsOpen(false)
    setFieldValue('dialCode', item.dialCode)
    inputRef?.current?.focus()
  }

  const onBlur = e => {
    if (!listRef?.current?.contains(e?.relatedTarget)) {
      setIsOpen(false)
    }
  }

  const onChange = e => {
    const item = countries.find(item => item.code === e.target.value)
    setFieldValue('dialCode', item.dialCode)
    inputRef?.current?.focus()
  }

  const inputFieldChange = e => {
    let value = e.target.value

    // Strip dial code if for example pasting
    if (value.indexOf(values.dialCode) === 0) {
      value = value.substr(values.dialCode.length)
    }
    setFieldValue(inputField.name, value)
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13 && onSubmit) onSubmit() 
  }

  useEffect(() => {
    if (isOpen && listRef.current) {
      listRef.current.scrollTop = renderAbove ? 50000 : 0
    }
  }, [isOpen, renderAbove, listRef])


  const selected = countries.find(item => item.dialCode === values.dialCode)

  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      <div className={styles.buttonWrapper}>
        <button className={cx(styles.button, { isOpen })} onClick={toggleOpen} onBlur={onBlur}>
          <Flag code={selected.code} />
          <span className={styles.icon}/>
        </button>
        {isMobile && <select value={selected.code} className={styles.select} onChange={onChange}>
          {countries && countries.map(item => {
            return <option key={item.code} value={item.code} dangerouslySetInnerHTML={{ __html: getFlagEmoji(item.code) + ' ' + item.country }} />
          })}
        </select>}
      </div>
      {!isMobile && isOpen && <div className={cx(styles.dropdown, { renderAbove })}>
        <ul ref={listRef} className={styles.list}>
          {countries && (renderAbove ? [...countries].reverse() : countries).map(item => {
            return <li key={item.code} className={styles.item} title={item.country}>
              <button className={styles.itemButton} onClick={() => selectItem(item)}>
                <Flag code={item.code} />
                <span className={styles.text}>{item.country}</span>
              </button>
            </li>
          })}
        </ul>
      </div>}
      <div className={styles.inputWrapper}>
        <div className={styles.dialCode}>{selected.dialCode}</div>
        <input 
          ref={inputRef} 
          className={styles.input} 
          type="text" 
          inputMode="numeric"
          autoComplete="tel-national"
          name={inputField.name}
          value={inputField.value}
          onBlur={inputField.onBlur}
          onChange={inputFieldChange}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  )
}
