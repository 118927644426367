import { useCallback, useState } from 'react';
import { useIsMounted } from "../useIsMounted/useIsMounted.js";
/**
 * Like `useState` but its state setter is guarded against setting the state of an unmounted component.
 */
export function useSafeState(initialState) {
    var _a = useState(initialState), state = _a[0], setState = _a[1];
    var isMounted = useIsMounted(true);
    return [
        state,
        useCallback(function (value) {
            if (isMounted())
                setState(value);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []),
    ];
}
