import { useCallback, useEffect, useRef } from 'react';
/**
 * Returns function that yields current mount state.
 *
 * Returned function yields `true` only in case component is mounted. This hook
 * is handy for the cases when you have to detect component mount state within
 * async effects.
 *
 * @param initialValue Initial value. By default, this hook assumes that hook is
 * not mounted yet at first render.
 */
export function useIsMounted(initialValue) {
    if (initialValue === void 0) { initialValue = false; }
    var isMounted = useRef(initialValue);
    var get = useCallback(function () { return isMounted.current; }, []);
    useEffect(function () {
        isMounted.current = true;
        return function () {
            isMounted.current = false;
        };
    }, []);
    return get;
}
