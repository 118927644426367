import { useContext } from 'react'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { addDays, addMinutes, clamp, roundToNearestMinutes, setHours, setMinutes, setSeconds } from 'date-fns'
import { motion, AnimatePresence } from "framer-motion"
import { InputField, CheckboxField, FormContent, FormHeader, ButtonDrawer, FormError, DatePicker, TimePicker, Notifications } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation } from '~/utils'
import * as common from '~/config/common.module.css'
import * as styles from './index.module.css'
import * as MotionElement from '~/components/ui/MotionElement'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const getMinDate = () => addMinutes(setSeconds(roundToNearestMinutes(new Date(), { nearestTo: 5 }), 0), 40)
const getMaxDate = () => addDays(setHours(setMinutes(setSeconds(new Date(), 0), 59), 23), 27)

const SelectTime = () => {
  const { t } = useTranslation()
  const { prev, next } = useContext(StepperContext)
  const wrappedNext = wrapWithValidation(next, useFormikContext())

  const { values, setFieldValue } = useFormikContext()
  const departureIsAirport = values.departure?.locationTypes?.includes('AIRPORT')

  const minDate = getMinDate()
  const maxDate = getMaxDate()
  const clampDate = date => clamp(date, { start: minDate, end: maxDate })

  const goNow = () => {
    setFieldValue('pickupTime', '', false)
    next()
  }

  const buttons = [
    { func: prev, isBack: true },
    { func: goNow, title: t('button-go-now'), disabled: departureIsAirport },
    { func: wrappedNext, title: t('button-continue') }, 
  ]

  return (
      <motion.div layout transition={{duration:.18}} className={common.widgetWrapper}>
        <Notifications />
        <FormContent>
          <FormHeader>{departureIsAirport ? t('header-when-does-your-flight-land') : t('header-when-do-you-want-to-go')}</FormHeader>
          <MotionElement.div className={common.content}>
            <div>
              <div className={styles.datepicker}>
                <Field name="pickupTime" minDate={minDate} maxDate={maxDate} clampDate={clampDate} component={DatePicker} />
              </div>
              <ErrorMessage name="pickupTime" component={FormError} />
            </div>
            <div className={common.group}>
              <Field name="pickupTime" minDate={minDate} maxDate={maxDate} clampDate={clampDate} component={TimePicker} />
              {departureIsAirport && <>
                <div>
                  <Field name="flightNumber" placeholder={t('placeholder-flight-number')} component={InputField} />
                  <p className={common.subtitle}>{t('info-flight-number')}</p>
                </div>
                <div>
                  <Field name="checkedInBaggage" title={t('title-baggage')} tabIndex="0" reverseLayout component={CheckboxField} />
                  <p className={common.subtitle}>{t('info-baggage')}</p>
                </div>
              </>}
            </div>
          </MotionElement.div>
        </FormContent>
        <ButtonDrawer buttons={buttons} />
      </motion.div>
  )
}

SelectTime.validationSchema = Yup.object({
  pickupTime: Yup.date()
    .min(getMinDate(), i18next.t('validation-date-not-valid'))
    .max(getMaxDate(), i18next.t('validation-date-too-far-ahead'))
    .required(i18next.t('validation-need-date')),
  flightNumber: Yup.string()
    .when('departure', (departure) => {
      return departure?.locationTypes?.includes('AIRPORT') ? Yup.string().required(i18next.t('validation-need-flight-number')) : Yup.string()
    }),
})

export default SelectTime
