import sha1 from 'sha1'
import { nanoid } from 'nanoid'
import { emitCustomEvent } from 'react-custom-events'
import { formatPriceEstimateRequest, formatBookingRequest, formatPackageBookingRequest, formatStatusRequest, formatLocationQuery } from '~/utils/format'
import { getErrorMessage } from './errors'
import i18next from '~/i18n'

export class ApiError extends Error {
  constructor(message, response) {
    super(message)
    this.name = this.constructor.name
    this.response = response
  }
}

export const hasToken = () => {
  return localStorage.getItem('token') !== null
}

export const isBadState = error => {
  // TODO: Add more error codes?
  return error?.response?.error === 'DEVICE_ACTIVATION_NOT_FOUND'
}

async function api(endPoint, body, method = 'GET', extraHeaders = null, returnFull = false) {
  const token = localStorage.getItem('token')

  const headers = {
    'Content-Type': 'application/json',
    'Accept-Language': i18next.language,
    'X-Device-Id': localStorage.getItem('uuid'),
    ...extraHeaders
  }
  if (token) headers['Authorization'] = token

  const res = await fetch(`/api${endPoint}`, {
    method,
    headers,
    body,
  })
  .catch((error) => {
    console.error(error)
  })

  if (returnFull && res.status === 200) return res

  const json = await res.json()

  // Check for API errors
  if (res.status !== 200) {
    if (json?.error) json.message = getErrorMessage(endPoint, json.error)

    if (res.status === 401 && json?.error === 'Unauthorized') {
      emitCustomEvent('UNAUTHORIZED')
    }

    throw new ApiError(`${json.error} ${json.message}`, json)
  }
  
  // Save user token
  if (res.headers.has('Authorization')) {
    localStorage.setItem('token', res.headers.get('Authorization'))
  }

  return json
}


/*
 *
 *  Locations
 *
 */
export async function searchLocation(value, streetOnly) {
  value = value.replace(/[\s,]+/g, ' ')
  const res = await api(`/v1/locations/search${streetOnly ? '/street-only' : ''}?address_string=${encodeURIComponent(value)}`)
  return res.result || {}
}

export async function fetchStreetNumbers(streetName, city) {
  const res = await api(`/v1/locations/numbers?street_name=${encodeURIComponent(streetName)}&city=${encodeURIComponent(city)}`)
  return res
}

export async function fetchLocationInfo(location) {
  const query = formatLocationQuery(location.address)
  const res = await api(`/v1/locations/${encodeURIComponent(query)}`)
  if (res?.address?.entrance === '') delete res.address.entrance
  // Add unique id to every location object to use as React key in the search locations component.
  // This allows duplicate addresses, for example if departure and destination is the same but with multiple stops in between.
  if (res?.address?.streetName) {
    res.key = nanoid(10)
  }
  return res
}

export async function searchNearby({ latitude, longitude, accuracy }) {
  // const res = await api(`/v1/locations/nearby?latitude=${latitude}&longitude=${longitude}&accuracy=${Math.ceil(accuracy)}`)
  const res = await api(`/v1/locations/nearby?latitude=${latitude}&longitude=${longitude}`)
  return res.result || {}
}

export async function fetchEta({ latitude, longitude }) {
  // NOTE: the API returns ETA for all vehicle types but taxi is the only accurate one so we disable this for now
  // const res = await api(`/v1/estimates/wait?latitude=${latitude}&longitude=${longitude}`)
  const res = await api(`/v1/estimates/wait?vehicleType=taxi&latitude=${latitude}&longitude=${longitude}`)
  return res
}


/*
 *
 *  Price estimate
 *
 */
export async function fetchPriceEstimate(values) {
  const req = formatPriceEstimateRequest(values)
  const res = await api('/v1/price/estimate', JSON.stringify(req), 'POST')
  return res
}


/*
 *
 *  OTP activation 
 *
 */
export async function initiateActivation(code, phone) {
  const formatted = code + phone.replace(/^0/, '')
  const req = { phone: formatted, locale: i18next.language }
  const res = await api('/v1/activation/initiate', JSON.stringify(req), 'POST')
  return res
}

export async function resendPhoneOtp() { 
  const res = await api('/v1/activation/resend-phone-otp', null, 'POST')
  return res
}

export async function validatePhoneOtp(otp) {
  const req = { otp }
  const res = await api('/v1/activation/validate-phone-otp', JSON.stringify(req), 'POST')
  return res
}

export async function resendEmailOtp() {
  const res = await api('/v1/activation/resend-email-otp', null, 'POST')
  return res
}

export async function validateEmailOtp(otp) {
  const req = { otp }
  const res = await api('/v1/activation/validate-email-otp', JSON.stringify(req), 'POST')
  return res
}

export async function addEmail(email) {
  const req = { email }
  const res = await api('/v1/activation/add-email', JSON.stringify(req), 'PATCH')
  return res
}

export async function addName(firstName, lastName) {
  const req = { firstName, lastName }
  const res = await api('/v1/activation/add-name', JSON.stringify(req), 'PATCH')
  return res
}

export async function completeActivation(req) {
  const res = await api('/v1/activation/complete', JSON.stringify(req), 'POST')
  return res
}


/*
 *
 *  User
 *
 */
export async function getUser(userId) {
  const res = await api(`/v1/users/${userId}`)
  return res
}

export async function updateUser(userId, { firstName, lastName, email, marketingOptIn }) {
  const req = { firstName, lastName, email, marketingOptIn }
  const res = await api(`/v1/users/${userId}`, JSON.stringify(req), 'PATCH')
  return res
}

export async function deleteUser(userId) {
  const res = await api(`/v1/users/${userId}`, null, 'DELETE')
  return res
}

export async function getAccounts(userId) {
  const res = await api(`/v1/users/${userId}/accounts`)
  return res
}

export async function addAccount(userId, accountNumber, accountPassword) {
  const req = { accountNumber, accountPassword: sha1(accountPassword.toUpperCase()) }
  const res = await api(`/v1/users/${userId}/accounts`, JSON.stringify(req), 'POST')
  return res
}

export async function deleteAccount(userId, accountNumber) {
  const res = await api(`/v1/users/${userId}/accounts/${accountNumber}`, null, 'DELETE')
  return res
}

export async function addVoucher(userId, voucherCode) {
  const req = { voucherCode: voucherCode.toUpperCase() }
  const res = await api(`/v1/users/${userId}/vouchers`, JSON.stringify(req), 'POST')
  return res
}

export async function logoutDevice() {
  const res = await api(`/v1/devices/logout`, null, 'DELETE')
  return res
}

// NOTE: This should be removed when all clients have switched to OTP
export async function verifyEmailToken(token) {
  return new Promise((resolve, reject) => {
    $.ajax(APP.getAjaxConfig({
      url: CONFIG.api_base_url + '/api/validate-token',
      method: 'POST',
      data: { token },
      success: resolve,
      error: function(jqXHR) {
        reject({ status: jqXHR.status })
      }
    }))
  })
}

export async function getUserVerificationToken(userId) {
  const headers = { 'User-Id': userId }
  const res = await api(`/v1/payments/user-verification-token`, null, 'GET', headers, true)
  if (res.headers.has('Payment-Token')) {
    return res.headers.get('Payment-Token').replace('Bearer ', '')
  }
  return null
}


/*
 *
 *  Bookings
 *
 */
export async function createBooking(values, userId) {
  const req = values?.serviceType === 'delivery' ? formatPackageBookingRequest(values, userId) : formatBookingRequest(values, userId)
  console.log(req)
  const res = await api('/v1/bookings', JSON.stringify(req), 'POST')
  return res
}

export async function prepareBooking(values, userId) {
  const req = values?.serviceType === 'delivery' ? formatPackageBookingRequest(values, userId) : formatBookingRequest(values, userId)
  const res = await api('/v1/bookings/prepare', JSON.stringify(req), 'POST')
  return res
}

export async function getBooking(bookingId) {
  const res = await api(`/v1/bookings/${bookingId}`)
  return res
}

export async function deleteBooking(bookingId, clientBookingToken) {
  // NOTE: Support anonymous bookings
  const headers = clientBookingToken ? { 'X-Order-Token': clientBookingToken } : null
  const res = await api(`/v1/bookings/${bookingId}`, null, 'DELETE', headers)
  return res
}

export async function sendConfirmationEmail(bookingId, email) {
  const req = { email }
  const res = await api(`/v1/bookings/${bookingId}/email-confirmation`, JSON.stringify(req), 'POST')
  return res
}

export async function getBookings(userId) {
  const res = await api(`/v1/bookings/users/${userId}?filter=active`)
  return res
}

export async function getHistoricBookings(userId) { const res = await api(`/v1/bookings/users/${userId}?filter=history&sort=DESC`)
  return res
}

export async function getStatus(bookings) {
  const req = formatStatusRequest(bookings)
  const res = await api(`/v1/bookings/status`, JSON.stringify(req), 'POST')
  return res
}

export async function getAnonymousBookings(bookings) {
  const req = bookings.map(booking => {
    return { bookingId: booking.orderId, clientBookingToken: booking.clientBookingToken }
  })
  const res = await api(`/v1/bookings/search`, JSON.stringify(req), 'POST')
  return res
}

export async function getPaymentToken(userId, bookingId) {
  const headers = { 'User-Id': userId }
  const req = { bookingId }
  const res = await api(`/v1/payments/payment-token`, JSON.stringify(req), 'POST', headers, true)
  if (res.headers.has('Payment-Token')) {
    return res.headers.get('Payment-Token').replace('Bearer ', '')
  }
  return null
}

export async function getPdfReceipt(userId, bookingId) {
  const headers = { 'User-Id': userId }
  const res = await api(`/v1/bookings/users/${userId}/${bookingId}/pdf-receipt`, null, 'GET', headers, true)
  return res
}


/*
 *
 *  Status messages
 *
 */
export async function getStatusMessages() {
  const date = new Date()
  const res = await api(`/v1/status-messages/search?dateTime=${date.toISOString()}`)
  return res
}
