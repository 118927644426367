import { useEffect, useRef } from 'react';
/**
 * Return boolean that is `true` only on first render.
 */
export function useFirstMountState() {
    var isFirstMount = useRef(true);
    useEffect(function () {
        isFirstMount.current = false;
    }, []);
    return isFirstMount.current;
}
