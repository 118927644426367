import { useContext, useEffect, useState, useRef } from 'react'
import { useFormikContext, Field } from 'formik'
import * as Yup from 'yup'
import { emitCustomEvent } from 'react-custom-events'
import classnames from 'classnames/bind'
import { AddLocation, ButtonDrawer, FocusElement, Icon, LocationSearch, FormContent, Notifications } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation, validateSetErrorsAndTouch } from '~/utils'
import * as styles from './index.module.css'
import * as common from '~/config/common.module.css'
import { motion, Reorder } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const cx = classnames.bind(common)
const cxs = classnames.bind(styles)

const hasValue = value => value !== '' && value !== '-' && value?.address !== undefined

const SelectLocations = () => {
  const { t } = useTranslation()
  const { next } = useContext(StepperContext)
  const wrappedNext = wrapWithValidation(next, useFormikContext())
  const validateForm = validateSetErrorsAndTouch(useFormikContext())

  const { values, setFieldValue } = useFormikContext()
  const [isAdding, setIsAdding] = useState(false)
  const isAddEnabled = !isAdding && 
    values.departure?.address &&
    values.destination?.address &&
    values.intermediateStopLocations?.length < 3
  const isReorderEnabled =
    values.departure?.address ||
    values.destination?.address ||
    values.intermediateStopLocations?.length > 0

  const intervalRef = useRef()

  const handleFocus = (event, location) => {
    const el = event.target.closest('li')
    if (el) {
      const setZindex = () => {
        el.style.zIndex = 2
      }
      clearInterval(intervalRef.current)
      intervalRef.current = setInterval(setZindex, 10)
      setZindex()
    }
    if (location?.address) {
      emitCustomEvent('ZOOM_LOCATION', location)
    }
    // Disable cover widget
    APP.trigger(APP.events.BOOKING_MODULE_DISABLE_HIDE_MAP)
  }

  const handleBlur = () => {
    clearInterval(intervalRef.current)
  }

  // NOTE: Reorder.Group needs a key to to be able to sort the items
  const getFields = () => {
    let fieldIndex = 0
    return [
      {
        // data: values.departure,
        data: hasValue(values.departure) ? values.departure : { key: 'departure' },
        element: <Field name="departure" fieldIndex={fieldIndex} placeholder={t('placeholder-origin-location')} component={LocationSearch} onFocus={(e) => handleFocus(e, values.departure)} onBlur={handleBlur} useLocation={true} />
      },
      ...values.intermediateStopLocations.map((location, index) => {
        fieldIndex++
        return {
          // data: location,
          data: hasValue(location) ? location : { key: `stop${index}` },
          element: <Field name={`intermediateStopLocations[${index}]`} fieldIndex={fieldIndex} placeholder={t('placeholder-add-stop')} component={LocationSearch} onFocus={(e) => handleFocus(e, location)} onBlur={handleBlur} />
        }
      }),
      {
        // data: values.destination,
        data: hasValue(values.destination) ? values.destination : { key: 'destination' },
        element: <Field name="destination" fieldIndex={fieldIndex + 1} placeholder={t('placeholder-destination-location')} component={LocationSearch} onFocus={(e) => handleFocus(e, values.destination)} onBlur={handleBlur} />
      }
    ]
  }

  const [fields, setFields] = useState(getFields())
  const [draggable, setDraggable] = useState(false)
  const ref = useRef()

  const gotoTime = () => {
    wrappedNext('time')
  }

  const gotoHow = () => {
    setFieldValue('pickupTime', '')
    wrappedNext('how')
  }

  const buttons = [
    { func: gotoTime, title: t('button-go-later'), isPrimary: values.departure?.locationTypes?.includes('AIRPORT') },
    { func: gotoHow, title: t('button-go-now'), disabled: values.departure?.locationTypes?.includes('AIRPORT') },
  ]

  const showAddStop = () => {
    setIsAdding(true)
  }

  const handleAddLocation = location => {
    setFieldValue('intermediateStopLocations', [...values.intermediateStopLocations, values.destination])
    setFieldValue('destination', location)
    setIsAdding(false)
  }

  const handleRemove = index => {
    if (typeof index === 'number') {
      if (fields.length > 2) {
        const items = fields.filter((v, i) => i !== index)
        setFields(items)
        updateValues(items)
      }
      else {
        setFieldValue(index === 0 ? 'departure' : 'destination', '-')
      }
    }
  }

  const handleReorder = items => {
    setFields(items)
  }

  const handleDragStart = e => {
    const el = e.target.closest('.' + styles.row)
    if (el) el.classList.add(styles.dragging)
  }

  const handleDragEnd = e => {
    const el = e.target.closest('.' + styles.row)
    if (el) el.classList.remove(styles.dragging)
    setDraggable(false)
    updateValues(fields)
  }

  const updateValues = (items) => {
    // setFieldValue('departure', items[0]?.data || '')
    // setFieldValue('destination', items[items.length - 1]?.data || '')
    setFieldValue('departure', items[0]?.data?.address ? items[0].data : '')
    setFieldValue('destination', items[items.length - 1]?.data?.address ? items[items.length - 1].data : '')

    if (items.length > 2) {
      const stops = items.slice(1, -1).map(item => item?.data?.address ? item.data : '')
      setFieldValue('intermediateStopLocations', stops)
    }
    else {
      setFieldValue('intermediateStopLocations', [])
    }

    setTimeout(() => validateForm(), 1)
  }

  useEffect(() => {
    APP.trigger(APP.events.BOOKING_MODULE_ENABLE_HIDE_MAP)
  }, [])

  useEffect(() => {
    setFields(getFields())
  }, [values.departure, values.destination, values.intermediateStopLocations])


    {/* <motion.div layout transition={{duration:.18}} className={cx(common.widgetWrapper, { tabsLeft: true })}> */}
  return (
    <div className={cx(common.widgetWrapper, { tabsLeft: true })}>
      <Notifications />
      <FocusElement />
      <FormContent>
        {/* <FormHeader className={styles.header}>{t('header-book-taxi')}</FormHeader> */}
        <Reorder.Group axis="y" values={fields} onReorder={handleReorder} ref={ref} className={styles.locations}>
          {fields.map((item, index) => (
            <Reorder.Item 
              key={item?.data?.key||index} 
              layout="position"
              value={item} 
              dragListener={draggable}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              dragConstraints={ref}
              dragElastic={0.05}
              className={styles.row}
            >
              <div className={styles.field}>
                {item.element}
                <div className={cxs(styles.dragHandle, { enabled: isReorderEnabled })}
                  onMouseEnter={() => setDraggable(true)}
                  onMouseLeave={() => setDraggable(false)} // retain this for better animation
                  onTouchStart={() => setDraggable(true)} // for mobile: need to set draggable to `false` in `onDragEnd` prop, not `onTouchEnd`
                >
                  <Icon id="drag" />
                </div>
              </div>
              <button onClick={() => handleRemove(index)} className={styles.removeButton} tabIndex="-1">
                <div className={styles.removeBackground}>
                  <Icon id="plus" />
                </div>
              </button>
            </Reorder.Item>
          ))}
        </Reorder.Group>
        {isAdding && <div className={styles.addLocationForm}><AddLocation onLocationSelected={handleAddLocation} /></div>}
        <button className={styles.addLocationButton} onClick={showAddStop} disabled={!isAddEnabled} tabIndex="0">{t('button-add-stop')}</button>
        <div className={styles.text}>{t('booking-login-info')}</div>
      </FormContent>
      <ButtonDrawer buttons={buttons} />
    </div>
  )
}

Yup.addMethod(Yup.object, 'validStop', function(errorMessage) {
  return this.test('test-valid-stop', errorMessage, function(value) {
    const { path, createError } = this
    return (
      !value?.locationTypes.includes('AIRPORT') || createError({ path, message: errorMessage })
    )
  })
})

SelectLocations.validationSchema = Yup.object({
  // TODO: This does not work when loading JSON from the server... 
  departure: Yup.object().nullable().required(i18next.t('validation-departure-required')),
  destination: Yup.object()
    .when('intermediateStopLocations', (intermediateStopLocations) => {
      return intermediateStopLocations?.length > 0 ? Yup.object().nullable().required(i18next.t('validation-destination-required')) : Yup.object().nullable()
    }),
  intermediateStopLocations: Yup.array().of(
    Yup.object().nullable().required(i18next.t('validation-destination-required')).validStop(i18next.t('validation-airport-not-allowed'))
  )
})

export default SelectLocations
