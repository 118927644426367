.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  border-radius: var(--border-radius);
  background: white;

  &.clickable {
    cursor: pointer;
    user-select: none;
    & > * {
      pointer-events: none;
    }
  }

  &.alternateBackground {
    background: var(--color-cool-gray);
  }

  &.reverseLayout {
    flex-direction: row-reverse;
    justify-content: left;
  }

  &.isDisabled {
    cursor: default;
    pointer-events: none;

    & > * {
      opacity: 0.2;
    }
  }

  &.larger {
    height: 73px; /* NOTE: Allows for an extra info line */
  }
}
