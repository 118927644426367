import { useContext, useEffect, useRef, useState } from 'react'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import { motion } from "framer-motion"
import classnames from 'classnames/bind'
import { motion } from "framer-motion"
import * as MotionElement from '~/components/ui/MotionElement'
import { 
  FormContent, FormHeader, BookingSummary, ButtonDrawer, CarRadioField, CheckboxField, 
  FieldButton, OptionsPanel, BoosterField, TitleInfo, FieldWrapper, Spinner, FormError, Notifications
} from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { StoreContext } from '~/store'
import { getCarTypes } from '~/utils/data'
import * as common from '~/config/common.module.css'
import { useTranslation } from 'react-i18next'

const cx = classnames.bind(common)

const renderPrice = (prices, serviceType) => {
  const price = prices.find(p => p.serviceType === serviceType)
  if (price === undefined) {
    console.error('Could not find price for servicetype:', serviceType)
    return null
  }
  if (price.finalPrice === price.fullPrice) return `${price.finalPrice}:-`
  else return <>{price.finalPrice}:- <s>{price.fullPrice}:-</s></>
}

const SelectHow = () => {
  const { t } = useTranslation()
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const buttonRef = useRef()
  const checkboxRef = useRef()
  const carTypeRef = useRef()
  const { prev, next } = useContext(StepperContext)
  const { user } = useContext(StoreContext)

  const { values, setFieldValue } = useFormikContext()
  const showAvailability = values.departure?.address !== undefined && values.pickupTime === ''
  const disableFixedPrice = values.destination?.address === undefined
  const showPrices = values.fixedPrice && values.priceEstimate
  
  const fields = getCarTypes().filter(c => c.value !== 'delivery').map(field => {
    const name = field.value === 'business' ? 'premium' : field.value
    if (showAvailability && field.value === 'taxi' && values.eta?.length > 0) {
      field.eta = values.eta.find(item => name === item.vehicleType)?.expectedWaitTime 
    }
    if (showPrices) field.price = renderPrice(values.priceEstimate.prices, field.value)
    if (field.value === values.serviceType) field.labelRef = carTypeRef
    return field
  })

  const openPanel = () => {
    setIsPanelOpen(true)
    setTimeout(() => {
      if (checkboxRef.current) checkboxRef.current.focus()
    }, 200)
  }

  const closePanel = () => {
    setIsPanelOpen(false)
    if (buttonRef.current) buttonRef.current.focus()
  }

  const wrappedNext = () => {
    if (!user) {
      next()
    }
    else {
      next('confirm')
    }
  }

  const buttons = [
    { func: prev, isBack: true },
    { func: wrappedNext, title: t('button-continue') },
  ]

  useEffect(() => {
    if (values.serviceType === 'business') {
      setFieldValue('pet', false, false)
      setFieldValue('booster', 0, false)
    }
  }, [values.serviceType])

  useEffect(() => {
    if (carTypeRef.current) carTypeRef.current.focus()
  }, [carTypeRef])

  return (
    <motion.div layout transition={{duration:.18}} className={common.widgetWrapper}>
      <Notifications />
      <FormContent>
        <FormHeader>{t('header-how-do-you-want-to-go')}</FormHeader>
        <BookingSummary values={values} showHow />
      </FormContent>
      <div className={common.contentBottom}>
        <MotionElement.div className={common.group}>
          {fields.map((field, index) => <Field key={index} type="radio" name="serviceType" component={CarRadioField} {...field} />)}
        </MotionElement.div>
        <MotionElement.div className={common.group}>
          <Field name="fixedPrice" title={t('title-fixed-price')} disabled={disableFixedPrice} tabIndex="0" alternateBackground component={CheckboxField}>
            {values.priceIsLoading && <Spinner />}
            <ErrorMessage name="fixedPrice" component={FormError} noMarginTop />
          </Field>
          <FieldButton title={t('title-booking-options')} info={t('info-booking-options')} innerRef={buttonRef} onClick={openPanel} disabled={values.serviceType === 'business'} alternateBackground />
        </MotionElement.div>
      </div>
      <ButtonDrawer buttons={buttons} />
      <OptionsPanel title={t('title-booking-options')} isOpen={isPanelOpen} onClose={closePanel}>
        <div className={cx(common.contentBottom)}>
          <Field name="pet" tabIndex={isPanelOpen ? 0 : -1} title={t('title-pet-option')} info={t('info-pet-option')} innerRef={checkboxRef} alternateBackground component={CheckboxField} />
          <Field name="stationWagon" tabIndex={isPanelOpen ? 0 : -1} title={t('title-stationwagon-option')} alternateBackground component={CheckboxField} />
          <FieldWrapper alternateBackground>
            <TitleInfo title={t('title-childseat-option')} info={t('info-childseat-option')} />
          </FieldWrapper>
          <Field name="booster" tabIndex={isPanelOpen ? 0 : -1} title={t('title-booster-option')} info={t('info-booster-option')} alternateBackground component={BoosterField} />
        </div>
      </OptionsPanel>
    </motion.div>
  )
}

SelectHow.validationSchema = null

export default SelectHow
