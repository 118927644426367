export var noop = function () { };
export var isBrowser = typeof window !== 'undefined' &&
    typeof navigator !== 'undefined' &&
    typeof document !== 'undefined';
/**
 * You should only be reaching for this function when you're attempting to prevent multiple
 * redefinitions of the same function. In-place strict equality checks are more performant.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var isStrictEqual = function (prev, next) { return prev === next; };
export var truthyAndArrayPredicate = function (conditions) {
    return conditions.every(Boolean);
};
export var truthyOrArrayPredicate = function (conditions) {
    return conditions.some(Boolean);
};
