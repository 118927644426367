.wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  gap: 8px;
}

.buttonWrapper {
  position: relative;
}

.button {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing);
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  font-size: 16px;
  border: none;
  border-radius: var(--border-radius);
  background: white;
  cursor: pointer;

  &.isOpen .icon {
    transform: scaleY(-1);
  }

  @media (--lg-down) {
    pointer-events: none;
  }
}

.icon {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
}

.select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  width: 100%;
  max-height: calc(40px * 7);
  padding: 0;
  margin-top: var(--spacing);
  background: white;
  border-radius: var(--border-radius);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  user-select: none;
  overflow: hidden;

  &.renderAbove {
    top: calc(0px - var(--spacing));
    margin-top: 0;
    transform: translateY(-100%);
    box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.15);
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.item {
  width: 100%;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.itemButton {
  display: flex;
  align-items: center;
  height: 40px;
  gap: var(--spacing);
  width: 100%;
  background: none;
  border: 0;
  outline: none;
  padding: 0 calc(var(--spacing) * 2);
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;

  @media (--hover) {
    &:hover {
      background: var(--color-yellow);
    }
  }
}

.flag {
  font-size: 30px;

  &.selected {
    line-height: 56px;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  gap: calc(var(--spacing) / 2);
  width: 100%;
  height: 56px;
  padding: 0 calc(var(--spacing) * 2);
  border-radius: var(--border-radius);
  background: white;
}

.dialCode {
  pointer-events: none;
  user-select: none;
}

.dialCode, .input {
  font-family: Arial; /* Use Arial to avoid buggy vertical centering */
  font-size: 16px;
  line-height: 56px;
  height: 100%;
}

.input {
  appearance: none;
  flex: 1;
  width: 100%;
  padding: 0;
  border: none;

  &:autofill,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }

  &:focus {
    outline: none;
  }
}
