import { useRef, useCallback } from 'react';
import { isNodeOrChild } from './utils/is-node-or-child.mjs';
import { addPointerEvent, usePointerEvent } from '../events/use-pointer-event.mjs';
import { useUnmountEffect } from '../utils/use-unmount-effect.mjs';
import { AnimationType } from '../render/utils/types.mjs';
import { isDragActive } from './drag/utils/lock.mjs';
import { pipe } from '../utils/pipe.mjs';

/**
 * @param handlers -
 * @internal
 */
function useTapGesture({ onTap, onTapStart, onTapCancel, whileTap, visualElement, ...props }) {
    const hasPressListeners = onTap || onTapStart || onTapCancel || whileTap;
    const isPressing = useRef(false);
    const cancelPointerEndListeners = useRef(null);
    /**
     * Only set listener to passive if there are no external listeners.
     */
    const eventOptions = {
        passive: !(onTapStart ||
            onTap ||
            onTapCancel ||
            props["onPointerDown"]),
    };
    function removePointerEndListener() {
        cancelPointerEndListeners.current && cancelPointerEndListeners.current();
        cancelPointerEndListeners.current = null;
    }
    function checkPointerEnd() {
        removePointerEndListener();
        isPressing.current = false;
        const latestProps = visualElement.getProps();
        if (latestProps.whileTap && visualElement.animationState) {
            visualElement.animationState.setActive(AnimationType.Tap, false);
        }
        return !isDragActive();
    }
    function onPointerUp(event, info) {
        var _a, _b, _c, _d;
        if (!checkPointerEnd())
            return;
        /**
         * We only count this as a tap gesture if the event.target is the same
         * as, or a child of, this component's element
         */
        !isNodeOrChild(visualElement.current, event.target)
            ? (_b = (_a = visualElement.getProps()).onTapCancel) === null || _b === void 0 ? void 0 : _b.call(_a, event, info)
            : (_d = (_c = visualElement.getProps()).onTap) === null || _d === void 0 ? void 0 : _d.call(_c, event, info);
    }
    function onPointerCancel(event, info) {
        var _a, _b;
        if (!checkPointerEnd())
            return;
        (_b = (_a = visualElement.getProps()).onTapCancel) === null || _b === void 0 ? void 0 : _b.call(_a, event, info);
    }
    const startPress = useCallback((event, info) => {
        var _a;
        removePointerEndListener();
        if (isPressing.current)
            return;
        isPressing.current = true;
        cancelPointerEndListeners.current = pipe(addPointerEvent(window, "pointerup", onPointerUp, eventOptions), addPointerEvent(window, "pointercancel", onPointerCancel, eventOptions));
        const latestProps = visualElement.getProps();
        /**
         * Ensure we trigger animations before firing event callback
         */
        if (latestProps.whileTap && visualElement.animationState) {
            visualElement.animationState.setActive(AnimationType.Tap, true);
        }
        (_a = latestProps.onTapStart) === null || _a === void 0 ? void 0 : _a.call(latestProps, event, info);
    }, [Boolean(onTapStart), visualElement]);
    usePointerEvent(visualElement, "pointerdown", hasPressListeners ? startPress : undefined, eventOptions);
    useUnmountEffect(removePointerEndListener);
}

export { useTapGesture };
