import { useContext, useEffect, useRef } from 'react'
import { Formik, useFormikContext } from 'formik'
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events'
import { StoreContext } from '~/store'
import { OtpFlow,  Fetcher, ViewStepper, ViewListener, CurrentView, ResetButton } from '~/components'
import { DebugListener } from '~/components/DebugPanel'
import { usePrevious, useDidMountEffect } from '~/utils/hooks'
import { getUserName } from '~/utils/format'
import { default as SelectLocations } from './SelectLocations'
import { default as SelectTime } from './SelectTime'
import { default as SelectHow } from './SelectHow'
import { default as ConfirmBooking } from './ConfirmBooking'
import { default as BookingDone } from './BookingDone'
import * as styles from './index.module.css'
import { motion, AnimatePresence, AnimateSharedLayout, LayoutGroup } from "framer-motion";
import { useTranslation } from 'react-i18next'

const getPaymentType = account => {
  if (account?.type === 'creditcard') {
    return `CreditCard-${account.accountId}`
  }
  else if (account?.type === 'TSAB' || account?.type === 'TCA') {
    return `Account-${account.accountNumber}`
  }
  return 'PayInCar'
}

const BookingModule = () => {
  const { t } = useTranslation()
  const { preferredPaymentAccount } = useContext(StoreContext)

  const initialValues = {
    pickupTime: '',
    departure: '',
    destination: '', 
    payment: getPaymentType(preferredPaymentAccount),
    account: preferredPaymentAccount,
    intermediateStopLocations: [],

    // pickupTime: new Date('2024-03-25 12:00:00'),
    // departure: {
    //   "address": {
    //     "streetName": "Kvarnen Restaurang",
    //     "city": "Stockholm",
    //     "zone": 172,
    //     "position": {
    //       "latitude": 59.31483,
    //       "longitude": 18.07383
    //     }
    //   },
    //   "locationTypes": [
    //     "RESTAURANT"
    //   ],
    //   "availableCars": 997,
    //   "pointOfInterest": true,
    //   "key": "9ECmEKYiu7"
    // },
    // destination: {
    //   "address": {
    //     "streetName": "Ölstugan Gullmarsplan Restaurang",
    //     "city": "Stockholm",
    //     "position": {
    //       "latitude": 59.297768,
    //       "longitude": 18.0797
    //     }
    //   },
    //   "locationTypes": [
    //     "RESTAURANT"
    //   ],
    //   "availableCars": 997,
    //   "pointOfInterest": true,
    //   "key": "oR1Kpe3cTM"
    // },
    // intermediateStopLocations: [],
    // payment: 'PayInCar',

    serviceType: 'taxi',
    flightNumber: '',
    checkedInBaggage: false,
    priceToken: '',
    pet: false,
    stationWagon: false,
    booster: 0,
    name: '',
    phone: '',
    generalComment: '',
    accountReference: '',
    fixedPrice: true,
    priceIsLoading: false,
    priceEstimate: '',
    originInfo: [],
    vouchers: [],
    voucherObjects: [],
  }

  const views = [
    { id: 'locations', component: SelectLocations },
    { id: 'time', component: SelectTime },
    { id: 'how', component: SelectHow },
    { id: 'otpflow', component: OtpFlow },
    { id: 'confirm', component: ConfirmBooking },
    { id: 'done', component: BookingDone },
  ]

  const handleViewChange = (id, prevId) => {
    if (id === 'locations' || prevId === 'locations') {
      emitCustomEvent('DISPLAY_TRIP')
    }
  }

  return (
    <AnimateSharedLayout>
      <motion.div      
        style={{originX: 0.5, originY: 1}}
        transition={{duration:.2}}
        className={styles.bookingwidget}
      >
        <AnimatePresence>
          <LayoutGroup>
            <ViewStepper views={views}>
              {({ View, id }) => {
                return (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={View.validationSchema}
                    validateOnChange={true}
                    validateOnBlur={id === 'locations'}
                    onSubmit={async (values) => {
                      console.log(values)
                    }}
                    onReset={() => {
                      APP.trigger(APP.events.BOOKING_MODULE_SHOW_COVER)
                      emitCustomEvent('RESET_FORM')
                      emitCustomEvent('RESET_MAP')
                    }}
                  >
                    <>
                      <Fetcher />
                      <CurrentView />
                      <ResetButton message={t('reset-booking-message')} />
                      <ViewListener onViewChange={handleViewChange} />
                      <FormikListener />
                      {process.env.NODE_ENV !== 'production' && <DebugListener id="main" />}
                    </>
                  </Formik>
                )
              }}
            </ViewStepper>
          </LayoutGroup>
        </AnimatePresence>
      </motion.div>
    </AnimateSharedLayout>
  )
}

const FormikListener = () => {
  const { user, preferredPaymentAccount } = useContext(StoreContext)
  const { values, dirty, setFieldValue } = useFormikContext()
  const prevDeparture = usePrevious(values.departure)
  const prevDestination = usePrevious(values.destination)
  const prevLocations = usePrevious([values.departure, ...values.intermediateStopLocations, values.destination])
  const hasUpdatedUser = useRef(false)

  // Update preferred acount and payment type on form reset
  useCustomEventListener('RESET_FORM', () => {
      console.log('setting the default payment:', preferredPaymentAccount)
      setTimeout(() => {
        setFieldValue('payment', getPaymentType(preferredPaymentAccount), false)
        setFieldValue('account', preferredPaymentAccount, false)
      }, 300)
    },
    [setFieldValue, preferredPaymentAccount]
  )

  // Update voucherObjects array when vouchers are changed
  // The objects are used to render details in the BookingSummary
  useEffect(() => {
    if (user && user.vouchers) {
      const voucherObjects = user.vouchers.filter(item => values.vouchers.includes(item.voucherCode))
      setFieldValue('voucherObjects', voucherObjects, false)
    }
  }, [values.vouchers])

  // Set username and phone if we have a logged in user
  useEffect(() => {
    if (user && (dirty === false || hasUpdatedUser.current === false)) {
      if (values.name === '') setFieldValue('name', getUserName(user), false)
      if (values.phone === '') setFieldValue('phone', user.phone, false)
      hasUpdatedUser.current =  true
    }
  }, [user, values, dirty, hasUpdatedUser, setFieldValue])

  const hasValue = value => value !== '' && value !== '-' && value?.address !== undefined

  // Trigger events to update the map
  useDidMountEffect(() => {
    const locations = [values.departure, ...values.intermediateStopLocations, values.destination]

    const locationAdded =
      locations.length > prevLocations?.length ||
      locations.length === prevLocations?.length && locations.some((item, index) => hasValue(item) && !hasValue(prevLocations[index]))
    // const locationRemoved = locations.length < prevLocations?.length || locations.length === prevLocations?.length && locations.some((item, index) => !hasValue(item) && hasValue(prevLocations[index]))
    // const orderChanged = locations.length === prevLocations?.length && !locationRemoved && locations.every(item => prevLocations.includes(item))

    // console.log(prevLocations)
    // console.log(locations)
    // console.log('locationAdded:', locationAdded)
    // console.log('locationRemoved:', locationRemoved)
    // console.log('orderChanged:', orderChanged)

    let indexAdded = null

    // If location is added, find index to zoom in on that item on the map
    if (locationAdded) {
      indexAdded = locations.findIndex((item, index) => item !== prevLocations[index]) 
      console.log('indexAdded:', indexAdded)
    }

    // If departure changed, get and set location info
    if (values.departure?.address && values.departure !== prevDeparture) {
      console.log('=== departure changed')
      const info = values.departure?.locationInformation?.filter(item => item.tag === 'INFO').map(item => item.message)
      console.log(info)
      // Default to fixed price if departure is Arlanda
      // if (values.departure?.locationTypes?.includes('AIRPORT') && values.departure?.address?.streetName?.indexOf('Arlanda') !== -1) {
      //   setFieldValue('fixedPrice', true)
      // }
      setFieldValue('originInfo', info, false)
    }

    // If removing destination, disable fixed price (and credit card payment)
    if (dirty && prevDeparture?.address !== undefined && values.destination?.address === undefined) {
      if (values.payment.indexOf('CreditCard') !== -1) {
        setFieldValue('payment', 'PayInCar')
        setFieldValue('account', false)
      }
      setFieldValue('fixedPrice', false)
    }

    // Update map with locations, change any empty locations to null
    emitCustomEvent('LOCATION_CHANGE', { 
      locations: locations.map(item => !hasValue(item) ? null : item), 
      active: indexAdded 
    })

    // Zoom out and display trip
    if (!locationAdded) {
      emitCustomEvent('DISPLAY_TRIP')
    }
  }, [values.departure, values.destination, values.intermediateStopLocations])

  return null
}

export default BookingModule
