import { useEffect, useState } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import { formatReadableDate, formatLocalDateTime, parseLocalDateTime } from '~/utils/format'
import 'react-datepicker/dist/react-datepicker.css'
import * as styles from './index.module.css'
import i18next from '~/i18n'

registerLocale('sv', sv)

export default function DatePicker({ field, form: { setFieldValue }, minDate, maxDate, clampDate }) {
  const [selectedDate, setSelectedDate] = useState(field.value || minDate)

  const setDate = date => {
    const clamped = date
    // const clamped = clampDate(date)
    setSelectedDate(clamped)
    setFieldValue(field.name, clamped)
  }

  const onDateChange = date => {
    setDate(date)
  }

  const onInputChange = e => {
    const date = parseLocalDateTime(e.target.value)
    setDate(date)
  }

  useEffect(() => {
    if (field.value) setSelectedDate(field.value)
  }, [field.value])

  useEffect(() => {
    if (!field.value) setDate(minDate)
  }, [])

  return (
    <div>
      <label htmlFor="datetime" className={styles.date}>
        {formatReadableDate(selectedDate)}
        <input 
          className={styles.input}
          type="datetime-local" 
          name="datetime" 
          id="datetime"
          value={formatLocalDateTime(selectedDate)} 
          onChange={onInputChange} 
        />
      </label>
      <ReactDatePicker 
        selected={selectedDate} 
        minDate={minDate}
        maxDate={maxDate}
        onChange={onDateChange} 
        locale={i18next.language}
        fixedHeight
        inline
      />
    </div>
  )
}
