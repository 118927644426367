import { useContext, useEffect, useRef, useState } from 'react'
import { useFormikContext, ErrorMessage } from 'formik'
import { emitCustomEvent } from 'react-custom-events'
import * as Yup from 'yup'
import { FormContent, FormHeader, FormError, ButtonDrawer, PhoneInput } from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation } from '~/utils'
import { initiateActivation } from '~/lib/api'
import * as common from '~/config/common.module.css'
import * as MotionElement from '~/components/ui/MotionElement'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'

const InputPhone = ({ isLogin }) => {
  const { t } = useTranslation()
  const { prev, next } = useContext(StepperContext)
  const { values, setFieldError } = useFormikContext()
  const [isLoading, setIsLoading] = useState(false)
  const inputRef = useRef()

  const makeRequest = () => {
    setIsLoading(true)
    initiateActivation(values.dialCode, values.phone).then((response) => {
      console.log(response)
      if (response?.deviceActivationState === 'PHONE_OTP_INITIATED') {
        next()
      }
    })
    .catch((error) => {
      console.error(error)
      setFieldError('phone', error?.response?.message)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const wrappedNext = wrapWithValidation(makeRequest, useFormikContext())
      
  const buttons = [
    isLogin ? { func: () => emitCustomEvent('SHOW_LOGIN', false), title: t('button-cancel') } : { func: prev, isBack: true },
    { func: wrappedNext, title: t('button-continue'), isLoading },
  ]

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [inputRef])

  return (
    <>
      <FormContent isLoading={isLoading}>
        <FormHeader>{t('header-confirm-phone')}</FormHeader>
        <MotionElement.div className={common.content}>
          <div>
            <PhoneInput innerRef={inputRef} onSubmit={wrappedNext} />
            <ErrorMessage name="phone" component={FormError} />
          </div>
          <div className={common.fieldDescription}>{t(isLogin ? 'info-confirm-phone-login' : 'info-confirm-phone')}</div>
        </MotionElement.div>
      </FormContent>
      <ButtonDrawer buttons={buttons} isLoading={isLoading} />
    </> 
  )
}

InputPhone.validationSchema = Yup.object({
  phone: Yup.string()
    .matches(/^\d+$/, i18next.t('validation-only-numbers'))
    .min(8, i18next.t('validation-length-phone'))
    .max(14, i18next.t('validation-length-phone'))
    .required(i18next.t('validation-need-phone')),
})

export default InputPhone
