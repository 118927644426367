import * as styles from './index.module.css'

export default function TitleInfo({ title, info, children }) {
  return (
    <div>
      <div className={styles.title}>
        {title}
        {children}
      </div>
      {info && <div className={styles.info}>{info}</div>}
    </div>
  )
}
