import { useState } from 'react'
import classnames from 'classnames/bind'
import { CloseButton } from '~/components'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function Modal({ isOpen, onClose, lightBackground, absolute, children }) {
  return (
    <div className={cx(styles.wrapper, { isOpen, lightBackground, absolute })}>
      <div className={cx(styles.container)}>
        <CloseButton onClick={onClose} small/>
        {children}
      </div>
    </div>
  )
}

