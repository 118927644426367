import classnames from 'classnames/bind'
import * as styles from './index.module.css'

const cx = classnames.bind(styles)

export default function FieldWrapper({ reverseLayout, alternateBackground, isDisabled, larger, children, onClick }) {
  return (
    <div className={cx(styles.wrapper, { reverseLayout, alternateBackground, isDisabled, larger, clickable: typeof onClick === 'function' })} onClick={onClick}>
      {children}
    </div>
  )
}
