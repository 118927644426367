import { AnimationType } from '../render/utils/types.mjs';
import { useDomEvent } from '../events/use-dom-event.mjs';
import { useCallback } from 'react';

/**
 *
 * @param props
 * @param ref
 * @internal
 */
function useFocusGesture({ whileFocus, visualElement, }) {
    const { animationState } = visualElement;
    const onFocus = useCallback(() => {
        animationState && animationState.setActive(AnimationType.Focus, true);
    }, [animationState]);
    const onBlur = useCallback(() => {
        animationState && animationState.setActive(AnimationType.Focus, false);
    }, [animationState]);
    useDomEvent(visualElement, "focus", whileFocus ? onFocus : undefined);
    useDomEvent(visualElement, "blur", whileFocus ? onBlur : undefined);
}

export { useFocusGesture };
