import { createElement, createContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events'
import { useDidMountEffect } from '~/utils/hooks'
import { fetchLocationInfo, getUser, getUserVerificationToken, getBookings, getStatus, hasToken, getAnonymousBookings } from '~/lib/api'
import { getUserAccounts } from '~/lib/piq'
import { hasEnded } from '~/utils/data'
import i18next from '~/i18n'

export const StoreContext = createContext()

const fetchInterval = 20 // Number of seconds between fetches of bookings / statuses

const config = {
  termsLink: i18next.language === 'sv' ? '/sv/hjalpcenter/boka/resevillkor/' : '/en/helpdesk/to-book/conditions-of-travel/',
  paymentTermsLink: i18next.language === 'sv' ? '/sv/hjalpcenter/boka/betalningsvillkor/' : '/en/helpdesk/to-book/payment-terms/'
}

const defaultStore = {
  user: null, bookings: null, paymentAccounts: null, preferredPaymentAccount: false
}

const readStore = () => {
  const item = localStorage.getItem('reactStore')
  if (item) return JSON.parse(item)
  return defaultStore
}

const writeStore = (store) => {
  const item = JSON.stringify(store)
  localStorage.setItem('reactStore', item)
}


export default function Store({ apps }) {
  // Read store from local storage on app init
  const [store, setStore] = useState(readStore())
  const intervalRef = useRef(null)
  const fetchRef = useRef(true)

  const setUser = user => {
    setStore(state => ({ ...state, user }))
  }

  const setBookings = bookings => {
    setStore(state => ({ ...state, bookings }))
  }

  const setPaymentAccounts = paymentAccounts => {
    setStore(state => ({ ...state, paymentAccounts }))
  }

  const setPreferredPaymentAccount = account => {
    setStore(state => ({ ...state, preferredPaymentAccount: account }))
  }

  // const updateBookings = bookings => {
  //   setStore(state => {
  //     const updated = bookings.map(booking => {
  //       return { ...booking, ...state.bookings?.find(item => item.id === booking.id) }
  //     })
  //     return { ...state, updated }
  //   })
  // }

  const isLoggedIn = () => {
    return hasToken() && store?.user
  }

  const loginUser = userId => {
    console.log('=== loginUser')
    getUser(userId).then((response) => {
      console.log(response)
      setUser(response)
      getPaymentAccounts(response)
    })
    .catch((error) => {
      console.error(error.response)
      if (error.response.error === 'USER_DOES_NOT_EXIST') {
        emitCustomEvent('UNAUTHORIZED')
      }
    })
  }

  const logoutUser = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('reactStore')
    location.href = '/'
  }

  const refreshUser = () => {
    if (store?.user?.userId) {
      loginUser(store?.user?.userId)
    }
  }

  const getPaymentAccounts = user => {
    getUserVerificationToken(user.userId).then((userVerificationToken) => {
      console.log('userVerificationToken:', userVerificationToken)
      getUserAccounts(user.userId, userVerificationToken).then((response) => {
        console.log(response)
        setPaymentAccounts(response.accounts) 
        if (store?.preferredPaymentAccount != false) {
          verifyPreferredAccount(store.preferredPaymentAccount, user?.accounts, response.accounts)
        }
      })
      .catch((error) => {
        console.error(error)
      })
    })
    .catch((error) => {
      console.error(error)
    })
  }

  const verifyPreferredAccount = (preferred, accounts, paymentAccounts) => {
    // Verify that the preferred account in store stil is available, otherwise set preferred account to false
    if ((preferred.type === 'creditcard' && !paymentAccounts.some(item => item.accountId === preferred?.accountId)) 
      || ((preferred.type === 'TSAB' || preferred.type === 'TCA') && !accounts.some(item => item.accountNumber === preferred?.accountNumber))) {
      console.log('The preferred account does NOT exist.')
      setPreferredPaymentAccount(false)
    }
    else {
      console.log('The preferred account DOES exist.')
    }
  }

  const getActiveBookings = async (userId) => {
    console.log('=== getActiveBookings')
    const id = userId || store?.user?.userId
    if (id) {
      try {
        let bookings = await getBookings(id)
        clearInterval(intervalRef.current)

        // Try to fetch any anonymous bookings made with the old API.
        // This should be removed when the site has been live for some time.
        if (localStorage?.orders) {
          try {
            let orders = JSON.parse(localStorage.orders)
            if (orders?.length > 0) {
              // Fetch anonymous bookings
              let anonBookings = await getAnonymousBookings(orders)

              // Skip ended bookings, add client booking token to the bookings to be able to fetch status
              anonBookings = anonBookings
                .filter(booking => !hasEnded(booking))
                .map(booking => {
                  const clientBookingToken = orders.find(item => item.orderId === booking.id)?.clientBookingToken
                  if (clientBookingToken) booking.clientBookingToken = clientBookingToken
                  return booking
                })

              // Concat and sort the bookings
              if (anonBookings?.length > 0) {
                bookings = [...bookings, ...anonBookings].sort((a, b) => a.pickUpTimeStamp < b.pickUpTimeStamp ? -1 : 1)
              }
            }
          }
          catch (e) {
            console.error(e)
          }
        }

        try {
          bookings = await getOriginLocationInfo(bookings)
        }
        catch (error) {
          console.error(error.response)
        }
        setBookings(bookings)
        if (bookings?.length > 0) {
          intervalRef.current = setInterval(() => {
            // NOTE: Every other call, fetch all bookings
            if (fetchRef.current) getBookingStatus(bookings)
            else getActiveBookings(id)
            fetchRef.current = !fetchRef.current
          }, 1000 * fetchInterval )
        }
        else {
          intervalRef.current = setInterval(() => getActiveBookings(id), 1000 * fetchInterval)
        }
      }
      catch (error) {
        console.error(error.response)
      }
    }
  }

  // Fetch location information for origin
  const getOriginLocationInfo = async (bookings) => {
    return Promise.all(bookings.map(async (booking) => {
      const response = await fetchLocationInfo({ address: booking.originLocation })
      const info = response?.locationInformation?.filter(item => item.tag === 'INFO').map(item => item.message)
      if (info) booking.originInfo = info
      return booking
    }))
  }

  const getBookingStatus = bookings => {
    getStatus(bookings).then((response) => {
      const updatedBookings = bookings.map(booking => {
        const status = response.find(item => item.bookingId === booking.id)?.bookingStatus
        if (status) booking.bookingStatus = status
        return booking
      })
      setBookings(updatedBookings)
    })
    .catch((error) => {
      console.error(error.response)
    })
  }

  const loginAndFetchBookings = async userId => {
    loginUser(userId)
    getActiveBookings(userId)
  }


  // Write store to local storage when it changes
  useDidMountEffect(() => {
    writeStore(store)
  }, [store])

  // Load user and bookings
  useEffect(() => {
    // If there is a user object (ie. logged in user), update from the API
    if (store?.user?.userId) {
      loginAndFetchBookings(store.user.userId)
    }
  }, [])


  // Handle unauthorized errors
  useCustomEventListener('UNAUTHORIZED', () => {
    APP.trigger(
      APP.events.SHOW_MODAL,
      COPY.general.unauthorized_error, [
        { title: COPY.booking.button_ok, callback: logoutUser },
      ]
    )
  })

  return (
    <StoreContext.Provider value={{ 
      config, user: store.user, bookings: store.bookings, paymentAccounts: store.paymentAccounts, preferredPaymentAccount: store.preferredPaymentAccount, 
      isLoggedIn, loginUser, logoutUser, loginAndFetchBookings, setUser, refreshUser, setPreferredPaymentAccount, getActiveBookings 
    }}>
      {apps.map((app) => {
        return createPortal(createElement(app.component, app?.props), app.el)
      })}
    </StoreContext.Provider>
  )
}

