import { useContext, useEffect, useRef, useState } from 'react'
import { useFormikContext, Field, ErrorMessage } from 'formik'
import { motion } from "framer-motion"
import classnames from 'classnames/bind'
import { emitCustomEvent } from 'react-custom-events'
import * as Yup from 'yup'
import { StoreContext } from '~/store'
import { 
  FormContent, FormHeader, BookingSummary, ButtonDrawer, CheckboxField, RadioField, 
  FieldButton, OptionsPanel, FieldDropDown, InputField, FormError, Modal, Notifications,
  AddVoucher, AddAccount
} 
from '~/components'
import { StepperContext } from '~/components/ui/ViewStepper'
import { wrapWithValidation } from '~/utils'
import { createBooking, prepareBooking } from '~/lib/api'
import { showErrorModal } from '~/utils'
import { getVoucherValue } from '~/utils/data'
import { usePrevious } from '~/utils/hooks'
import * as MotionElement from '~/components/ui/MotionElement'
import * as common from '~/config/common.module.css'
import * as styles from './index.module.css'
import * as panelStyles from '~/components/ui/OptionsPanel/index.module.css'
import { useTranslation, Trans } from 'react-i18next'
import i18next from '~/i18n'
import { formatBookingRequest, formatReadableDateOnly } from '../../../utils/format'
import { track } from '../../../utils/gtm'

const getPaymentText = (values) => {
  if (values.payment.indexOf('Account') !== -1) {
    return values?.account?.accountName
  }
  else if (values.payment.indexOf('CreditCard') !== -1) {
    return values?.account?.maskedAccount
  }
  return i18next.t('title-pay-in-car')
}

const renderVoucherInfo = voucher => {
  let str = i18next.t('expires-at', { date: formatReadableDateOnly(new Date(voucher.expiry)) })
  if (voucher.valueType == 'PERCENTAGE') {
    return <>{str}<br />{i18next.t('info-voucher-max', { amount: voucher.maxPriceToBeDiscounted })}</>
  }
  return str
}

const cx = classnames.bind(common)

const ConfirmBooking = () => {
  const { t } = useTranslation()
  const paymentButtonRef = useRef()
  const payInCarRef = useRef()
  const optionsButtonRef = useRef()
  const messageFieldRef = useRef()
  const nameFieldRef = useRef()
  const [isPaymentOpen, setIsPaymentOpen] = useState(false)
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [isAddAccountOpen, setIsAddAccountOpen] = useState(false)
  const [isAddVoucherOpen, setIsAddVoucherOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { prev, next } = useContext(StepperContext)
  const { config, user, paymentAccounts, refreshUser, setPreferredPaymentAccount, getActiveBookings } = useContext(StoreContext)

  const { values, setFieldValue } = useFormikContext()

  const prevAccounts = usePrevious(user?.accounts)
  const prevPaymentAccounts = usePrevious(paymentAccounts)
  const prevVouchers = usePrevious(user?.vouchers)

  const hasAccounts = user?.accounts?.length > 0
  const hasCreditCards = paymentAccounts?.length > 0
  const hasVouchers = user?.vouchers?.length > 0


  const handleBookingComplete = (response) => {
    setIsLoading(false)
    getActiveBookings()
    refreshUser()
    sendToGTM(values, response.bookingId)
    setPreferredPaymentAccount(values.account)
    setFieldValue('bookingId', response.bookingId, false)
    next()
  }

  const makeRequest = () => {
    const isCreditCard = values.payment.indexOf('CreditCard') !== -1
    const bookingFunc = isCreditCard ? prepareBooking : createBooking
    
    setIsLoading(true)
    bookingFunc(values, user.userId).then((response) => {
      console.log(response)
      if (!isNaN(response?.bookingId)) {
        if (isCreditCard) {
          emitCustomEvent(
            'CASHIER_PAY_BOOKING', 
            {
              bookingId: response.bookingId,
              accountId: values.account.accountId, 
              success: () => handleBookingComplete(response),
              doneLoading: () => setIsLoading(false)
            }
          )
        }
        else {
          handleBookingComplete(response)
        }
      }
    })
    .catch((error) => {
      if (error?.response?.error === 'INVALID_PHONE_NUMBER') {
        setFieldError('phone', error?.response?.message)
      }
      else if (!(error?.response?.status === 401 && error?.response?.error === 'Unauthorized')) {
        console.error(error)
        showErrorModal(error?.response?.message)
      }
      setIsLoading(false)
    })
    // .finally(() => {
    //   setIsLoading(false)
    // })
  }

  const wrappedPrev = () => {
    prev('how')
  }

  const sendToGTM = (values, bookingId) => {
    const booking = formatBookingRequest(values);
    const priceComponents = values.fixedPrice ? values.priceEstimate?.prices?.filter((value) => {return value.serviceType === booking.serviceType}) : false;
    const prices = priceComponents && priceComponents.length > 0 ? priceComponents[0].priceComponents?.filter((value) => {return value.type === 'fixedPrice'}) : false;
    const price = prices && prices.length > 0 ? prices[0].amount : false;
    const trackData = {
      price: price,
      preBook: values.pickupTime ? true : false,
      pickupTime: booking.pickUpTimeStamp,
      bookingNumber: bookingId,
      carType: booking.serviceType,
      extras: booking.vehicleOptions,
      fixedPrice: booking.priceToken ? true : false
    };
    track('confirmBooking', trackData);
  }

  const wrappedNext = wrapWithValidation(makeRequest, useFormikContext())

  const setAccount = account => {
    if (!account) {
      setFieldValue('accountReference', '', false)
    }
    setFieldValue('account', account, false)
  }

  const openOptions = () => {
    setIsOptionsOpen(true)
    setTimeout(() => {
      if (messageFieldRef.current) messageFieldRef.current.focus()
    }, 200)
  }

  const closeOptions = () => {
    setIsOptionsOpen(false)
    if (optionsButtonRef.current) optionsButtonRef.current.focus()
  }

  const openPayment = () => {
    setIsPaymentOpen(true)
    setTimeout(() => {
      if (payInCarRef.current) payInCarRef.current.focus()
    }, 200)
  }

  const closePayment = () => {
    setIsPaymentOpen(false)
    if (paymentButtonRef.current) paymentButtonRef.current.focus()
  }

  const addCard = () => {
    closeOptions()
    emitCustomEvent('CASHIER_ADD_CARD', { success: refreshUser })
  }

  const openAddAccount = () => {
    setIsAddAccountOpen(true)
  }

  const closeAddAccount = () => {
    setIsAddAccountOpen(false)
  }

  const openAddVoucher = voucher => {
    setIsAddVoucherOpen(true)
  }

  const closeAddVoucher = () => {
    setIsAddVoucherOpen(false)
  }

  const buttons = [
    { func: wrappedPrev, isBack: true },
    { func: wrappedNext, title: t('button-book-now'), isLoading },
  ]

  useEffect(() => {
    if (user?.accounts?.length > prevAccounts?.length) {
      const addedAccount = user?.accounts?.find(a => {
        return (prevAccounts.find(b => b.accountNumber === a.accountNumber) === undefined) ? true : false
      })
      if (addedAccount !== undefined) {
        setFieldValue('payment', `Account-${addedAccount.accountNumber}`, false)
        setAccount(addedAccount)
      }
    }
  }, [user?.accounts])

  useEffect(() => {
    if (paymentAccounts?.length > prevPaymentAccounts?.length) {
      const addedAccount = paymentAccounts.find(a => {
        return (prevPaymentAccounts.find(b => b.accountId === a.accountId) === undefined) ? true : false
      })
      if (addedAccount !== undefined) {
        setFieldValue('payment', `CreditCard-${addedAccount.accountId}`, false)
        setAccount(addedAccount)
      }
    }
  }, [paymentAccounts])

  useEffect(() => {
    if (user?.vouchers?.length > prevVouchers?.length) {
      const addedVoucher = user?.vouchers?.find(a => {
        return (prevVouchers.find(b => b.voucherCode === a.voucherCode) === undefined) ? true : false
      })
      if (addedVoucher !== undefined) {
        setFieldValue('vouchers', [addedVoucher.voucherCode], false)
      }
    }
  }, [user?.vouchers])

  useEffect(() => {
    if (nameFieldRef.current) nameFieldRef.current.focus()
  }, [nameFieldRef])

  return (
    <motion.div layout transition={{duration:.18}} className={cx(common.widgetWrapper, { isLoading })}>
      <Notifications />
      <FormContent>
        <FormHeader>{t('header-confirm-your-booking')}</FormHeader>
        <BookingSummary values={values} showHow showPayment />
      </FormContent>
      <MotionElement.div className={common.contentBottom}>
        <Field name="name" placeholder={t('placeholder-name')} innerRef={nameFieldRef} component={InputField} border />
        <Field name="phone" placeholder={t('placeholder-phone')} component={InputField} border />
        <FieldButton title={t('title-payment')} innerRef={paymentButtonRef} onClick={openPayment} alternateBackground>
          {values.vouchers.length === 0 && user?.vouchers?.length > 0 && <div className={cx(common.option)}>{t('info-num-vouchers', { count: user.vouchers.length })}</div>}
        </FieldButton>
        <FieldButton title={t('title-message-to-driver')} innerRef={optionsButtonRef} onClick={openOptions} alternateBackground />
        {(values?.account?.type === 'TSAB' ||  values?.account?.type === 'TCA') && <div className={common.fullWidth}>
          <Field name="accountReference" placeholder={t('placeholder-reference')} component={InputField} alternateBackground />
        </div>}
        {values?.generalComment && <div className={cx(common.fullWidth, styles.message)}>
          <div className={common.label}>{t('title-message-to-driver')}</div>
          <div className={common.title}>{values.generalComment}</div>
        </div>}
        <div className={cx(common.fullWidth, styles.info)}>
          <Trans 
            i18nKey="info-accept-booking-terms" 
            components={{
              link1: <a href={config.termsLink} target="_blank"/>
            }}
          />
        </div>
      </MotionElement.div>
      <ButtonDrawer buttons={buttons} />
      <OptionsPanel isOpen={isPaymentOpen} onClose={closePayment} noPadding alternateBackground>
        <div className={styles.panelPadding}>
          <div className={panelStyles.title}>{t('title-payment')}</div>
          <div className={cx(common.contentBottom, { noBackground: true })}>
            <Field type="radio" name="payment" title={t('title-pay-in-car')} value="PayInCar" onClick={()=>setAccount(false)} innerRef={payInCarRef} component={RadioField} />
            <div className={cx(styles.subHeader, styles.marginTop, common.fullWidth)}>{t('header-card')}</div>
            {!hasCreditCards && <div className={styles.instructions}>{t('info-no-cards')}</div>}
            {paymentAccounts?.map((account, index) => (
              <Field 
                key={index} 
                type="radio" 
                name="payment" 
                title={account.maskedAccount} 
                info={account.cardType} 
                value={`CreditCard-${account.accountId}`} 
                onClick={()=>setAccount(account)} 
                tabIndex={isPaymentOpen ? 0 : -1} 
                disabled={!values.fixedPrice} 
                component={RadioField} 
              />
            ))}
            <div className={cx(styles.instructions, common.fullWidth)}>
              <button className={styles.button} onClick={addCard} tabIndex={isPaymentOpen ? 0 : -1}>+ {t('button-add-new-card')}</button>
            </div>
            <div className={cx(styles.subHeader, styles.marginTop, common.fullWidth)}>{t('header-account')}</div>
            {!hasAccounts && <div className={styles.instructions}>{t('info-no-accounts')}</div>}
            {user?.accounts?.map((account, index) => (
              <Field 
                key={index} 
                type="radio" 
                name="payment" 
                title={account.accountName} 
                info={account.accountNumber} 
                value={`Account-${account.accountNumber}`} 
                onClick={()=>setAccount(account)} 
                tabIndex={isPaymentOpen ? 0 : -1}
                component={RadioField} 
              />
            ))}
            <div className={cx(styles.instructions, common.fullWidth)}>
              <button className={styles.button} onClick={openAddAccount} tabIndex={isPaymentOpen ? 0 : -1}>+ {t('button-add-new-account')}</button>
            </div>
          </div>
        </div>
        <div className={cx(styles.panelPadding, styles.panelGray)}>
          <div className={panelStyles.title}>{t('title-vouchers')}</div>
          <div className={cx(common.contentBottom, { noBackground: true })}>
            {!hasVouchers && <div className={styles.instructions}>{t('info-no-vouchers')}</div>}
            {user?.vouchers?.map((voucher, index) => (
              <Field 
                key={index} 
                name="vouchers" 
                value={voucher.voucherCode} 
                title={`${voucher.voucherCode} - ${getVoucherValue(voucher)}`}
                info={renderVoucherInfo(voucher)}
                disabled={values?.vouchers?.length > 0 && !values?.vouchers?.includes(voucher.voucherCode)} 
                tabIndex={isPaymentOpen ? 0 : -1}
                component={CheckboxField} 
                larger
              />
            ))}
            <div className={cx(styles.instructions, common.fullWidth)}>
              <button className={styles.button} onClick={openAddVoucher} tabIndex={isPaymentOpen ? 0 : -1}>+ {t('button-add-new-voucher')}</button>
            </div>
            {hasVouchers && <div className={cx(styles.instructions, common.fullWidth)}>
              <br />
              <Trans 
                i18nKey="info-voucher-codes" 
                components={{
                  link1: <a href={config.paymentTermsLink} target="_blank"/>
                }}
              />
           </div>}
          </div>
        </div>
        {isAddAccountOpen && <Modal onClose={closeAddAccount} isOpen>
          <AddAccount onComplete={closeAddAccount} />
        </Modal>}
        {isAddVoucherOpen && <Modal onClose={closeAddVoucher} isOpen>
          <AddVoucher onComplete={closeAddVoucher} />
        </Modal>}
      </OptionsPanel>
      <OptionsPanel title={t('title-message')} isOpen={isOptionsOpen} onClose={closeOptions}>
        <div className={cx(common.contentBottom)}>
          <div className={cx(styles.instructions, common.fullWidth)}>{t('info-message-to-driver')}</div>
          <div className={common.fullWidth}>
            <Field name="generalComment" placeholder={t('title-message-to-driver')} tabIndex={isOptionsOpen ? 0 : -1} innerRef={messageFieldRef} maxLength="30" component={InputField} alternateBackground />
          </div>
        </div>
      </OptionsPanel>
    </motion.div>
  )
}

ConfirmBooking.validationSchema = Yup.object({
  name: Yup.string().required(i18next.t('validation-need-name')),
  phone: Yup.string()
    .matches(/^\+?\d+$/, i18next.t('validation-only-numbers'))
    .required(i18next.t('validation-need-phone')),
  accountReference: Yup.string()
    .when('account', (account) => {
      return account && (account.type ==='TSAB' || account.type ==='TCA') && 
        account.flags.includes('reference') ? Yup.string().required(i18next.t('validation-need-reference')) : Yup.string()
    }),
})

export default ConfirmBooking
