import { createContext, useContext, useEffect, useState } from 'react'
import { emitCustomEvent } from 'react-custom-events'

export const StepperContext = createContext()

export const CurrentView = () => {
  const { View, props } = useContext(StepperContext)
  return <View {...props} />
}

export default function ViewStepper({ views, complete, children }) {
  if (!views?.length) return console.error('No views supplied')

  const parentContext = useContext(StepperContext)

  const [steps, setSteps] = useState([views[0].id])

  const getCurrentId = () => {
    return steps[steps.length - 1]
  }

  const getCurrentView = () => {
    const id = getCurrentId()
    const view = views.find(item => item.id === id)
    return [id, view?.component, view?.props]
  }

  const prev = (id) => {
    // If a requested id is not in steps but in views ie. jump back to a prev not visited view...
    if (typeof id === 'string' && steps.findIndex(item => item === id) === -1 && views.findIndex(item => item.id === id)) {
      const index = views.findIndex(item => item.id === id)
      setSteps(views.filter((item, i) => i < index + 1).map(item => item.id))
    }
    else if (typeof id === 'string' && steps.findIndex(item => item === id) !== -1) {
      const index = steps.findIndex(item => item === id)
      setSteps(previous => previous.slice(0, index + 1)) 
    }
    else if (steps.length > 1) {
      setSteps(previous => previous.slice(0, previous.length - 1))
    }
    else if (parentContext?.prev) {
      parentContext.prev(id)
    }
  }

  const next = (id) => {
    if (typeof id !== 'string') {
      const index = views.findIndex(item => item.id === getCurrentId())
      if (index + 1 < views.length) {
        id = views[index + 1].id
      }
      else if (parentContext?.next) {
        parentContext.next()
      }
      else if (complete) {
        complete()
      }
    }
    if (typeof id === 'string' && views.findIndex(item => item.id === id) !== -1) {
      setSteps(previous => [...previous, id]) 
    }
    // TODO: Remove this, used for debugging only
    // else {
    //   console.error(`No view "${id}" found!`)
    // }
  }

  const reset = () => {
    setSteps([views[0].id])
  }

  const [id, View, props] = getCurrentView()

  // Emit event with the current id for parent components
  // NOTE: maybe add an id for the stepper also
  useEffect(() => {
    emitCustomEvent('VIEW_CHANGE', { id })
  }, [id])

  return (
    <StepperContext.Provider value={{ 
      prev, 
      next, 
      reset,
      parentContext, 
      id,
      View,
      props
    }}>
      {typeof children !== 'function' ? children : children({ prev, next, reset, parentContext, id, View, props })}
    </StepperContext.Provider>
  )
}
