import { useEffect, useRef } from 'react'
import { Field, Formik, useFormikContext } from 'formik'
import { usePrevious } from '~/utils/hooks'
import { LocationSearch } from '~/components'
import { useTranslation } from 'react-i18next'

export default function AddLocation({ onLocationSelected }) {
  const { t } = useTranslation()
  const ref = useRef()

  useEffect(() => {
    ref.current?.focus()
  }, [ref])

  return (
    <Formik initialValues={{ destination: '' }}>
      <>
        <FormikListener onLocationSelected={onLocationSelected} />
        <Field name="destination" placeholder={t('placeholder-add-stop')} innerRef={ref} component={LocationSearch} />
      </>
    </Formik>
  )
}
const FormikListener = ({ onLocationSelected }) => {
  const { values } = useFormikContext()
  const prevLocation = usePrevious(values.destination)

  useEffect(() => {
    if (values.destination?.address && values.destination !== prevLocation) {
      onLocationSelected(values.destination)
    }
  }, [values.destination])

  return null
}
