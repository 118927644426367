import { useContext, useEffect, useRef } from 'react'
import { Formik, useFormikContext } from 'formik'
import { emitCustomEvent } from 'react-custom-events'
import { StoreContext } from '~/store'
import { OtpFlow,  Fetcher, ViewStepper, ViewListener, CurrentView, ResetButton } from '~/components'
import { DebugListener } from '~/components/DebugPanel'
import { usePrevious } from '~/utils/hooks'
import { getUserName } from '~/utils/format'
import { default as SelectLocations } from './SelectLocations'
import { default as SelectTime } from './SelectTime'
import { default as SelectWhat } from './SelectWhat'
import { default as SelectSender } from './SelectSender'
import { default as SelectReceiver } from './SelectReceiver'
import { default as ConfirmBooking } from './ConfirmBooking'
import { default as BookingDone } from './BookingDone'
import * as styles from './index.module.css'
import { motion, AnimatePresence, AnimateSharedLayout, LayoutGroup } from "framer-motion";
import { useTranslation } from 'react-i18next'

const PackageModule = () => {
  const { t } = useTranslation()

  let initialValues = {
    pickupTime: '',
    departure: '',
    destination: '',
    serviceType: 'delivery',
    priceToken: '',
    ENVELOPE: 0,
    BOX: 0,
    BAG: 0,
    MOVING_BOX: 0,
    payment: 'PayInCar',
    name: '',
    phone: '',
    generalComment: '',
    accountReference: '',
    account: false,
    fixedPrice: true,
    priceIsLoading: false,
    priceEstimate: '',
    originInfo: [],
    packageDeliveryInfo: {
      packageSender: {
        nameOnDoor: '',
        doorCode: '',
        numberOfStairs: ''
      },
      packageReceiver: {
        nameOnDoor: '',
        phoneNumber: '',
        doorCode: '',
        numberOfStairs: '',
        leaveOutsideDoor: false
      },
      otherInformation: ''
    }
  }

//   let temp = {
//     "pickupTime": new Date('2023-05-13T13:30:00.000Z'),
//     "departure": {
//       "address": {
//         "position": {
//           "latitude": 59.31483,
//           "longitude": 18.07383
//         },
//         "zone": 172,
//         "streetName": "Kvarnen Restaurang",
//         "city": "Stockholm"
//       },
//       "locationTypes": [
//         "RESTAURANT"
//       ],
//       "availableCars": 997,
//       "pointOfInterest": true
//     },
//     "destination": {
//       "address": {
//         "position": {
//           "latitude": 59.29792,
//           "longitude": 18.07497
//         },
//         "zone": 811,
//         "streetName": "Etsarvägen",
//         "streetNumber": "2",
//         "city": "Stockholm"
//       },
//       "locationTypes": [
//         "ADDRESS"
//       ],
//       "availableCars": 997,
//       "pointOfInterest": false
//     },
//     "serviceType": "delivery",
//     "priceToken": "",
//     "ENVELOPE": 4,
//     "BOX": 0,
//     "BAG": 0,
//     "MOVING_BOX": 1,
//     "packageDeliveryInfo": {
//       "packageSender": {
//         "nameOnDoor": "We Are Yours",
//         "doorCode": "1234",
//         "numberOfStairs": "4"
//       },
//       "packageReceiver": {
//         "nameOnDoor": "",
//         "phoneNumber": "",
//         "doorCode": "",
//         "numberOfStairs": "",
//         "leaveOutsideDoor": false
//       }
//     }
//   }
//   initialValues = { ...initialValues, ...temp }

  // initialValues = {
  //   "pickupTime": new Date('2023-05-13T13:30:00.000Z'),
  //   "departure": {
  //     "address": {
  //       "position": {
  //         "latitude": 59.31483,
  //         "longitude": 18.07383
  //       },
  //       "zone": 172,
  //       "streetName": "Kvarnen Restaurang",
  //       "city": "Stockholm"
  //     },
  //     "locationTypes": [
  //       "RESTAURANT"
  //     ],
  //     "availableCars": 997,
  //     "pointOfInterest": true
  //   },
  //   "destination": {
  //     "address": {
  //       "position": {
  //         "latitude": 59.29792,
  //         "longitude": 18.07497
  //       },
  //       "zone": 811,
  //       "streetName": "Etsarvägen",
  //       "streetNumber": "2",
  //       "city": "Stockholm"
  //     },
  //     "locationTypes": [
  //       "ADDRESS"
  //     ],
  //     "availableCars": 997,
  //     "pointOfInterest": false
  //   },
  //   "serviceType": "delivery",
  //   "priceToken": "",
  //   "ENVELOPE": 4,
  //   "BOX": 0,
  //   "BAG": 0,
  //   "MOVING_BOX": 1,
  //   "payment": "PayInCar",
  //   "name": "David Genelid",
  //   "phone": "0702515824",
  //   "generalComment": "",
  //   "accountReference": "",
  //   "account": false,
  //   "fixedPrice": true,
  //   "priceIsLoading": false,
  //   "priceEstimate": "",
  //   "packageDeliveryInfo": {
  //     "packageSender": {
  //       "nameOnDoor": "We Are Yours",
  //       "doorCode": "1234",
  //       "numberOfStairs": "4"
  //     },
  //     "packageReceiver": {
  //       "nameOnDoor": "Karl Wahlström",
  //       "phoneNumber": "08123456789",
  //       "doorCode": "9988",
  //       "numberOfStairs": "3",
  //       "leaveOutsideDoor": false
  //     },
  //     "otherInformation": "Ring innan lämning."
  //   }
  // }


  const views = [
    { id: 'locations', component: SelectLocations },
    { id: 'time', component: SelectTime },
    { id: 'what', component: SelectWhat },
    { id: 'otpflow', component: OtpFlow },
    { id: 'sender', component: SelectSender },
    { id: 'receiver', component: SelectReceiver },
    { id: 'confirm', component: ConfirmBooking },
    { id: 'done', component: BookingDone },
  ]

  const handleViewChange = (id, prevId) => {
    if (id === 'locations' || prevId === 'locations') {
      emitCustomEvent('DISPLAY_TRIP')
    }
  }

  return (
    <AnimateSharedLayout>
      <motion.div
        style={{originX: 0.5, originY: 1}}
        transition={{duration:.2}}
        className={styles.bookingwidget}
      >
        <AnimatePresence>
          <LayoutGroup>
            <ViewStepper views={views}>
              {({ View }) => {
                return (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={View.validationSchema}
                    validateOnChange={true}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                      console.log(values)
                    }}
                    onReset={() => {
                      APP.trigger(APP.events.BOOKING_MODULE_SHOW_COVER)
                      emitCustomEvent('RESET_FORM')
                      emitCustomEvent('RESET_MAP')
                    }}
                  >
                    <>
                      <Fetcher />
                      <CurrentView />
                      <ResetButton message={t('reset-booking-message')} />
                      <ViewListener onViewChange={handleViewChange} />
                      <FormikListener />
                      {process.env.NODE_ENV !== 'production' && <DebugListener id="package" />}
                    </>
                  </Formik>
                )
              }}
            </ViewStepper>
          </LayoutGroup>
        </AnimatePresence>
      </motion.div>
    </AnimateSharedLayout>
  )
}

const FormikListener = () => {
  const { user } = useContext(StoreContext)
  const { values, dirty, setFieldValue } = useFormikContext()
  const prevDeparture = usePrevious(values.departure)
  const prevDestination = usePrevious(values.destination)
  const hasUpdatedUser = useRef(false)

  // Set username and phone if we have a logged in user
  useEffect(() => {
    if (user && (dirty === false || hasUpdatedUser.current === false)) {
      if (values.name === '') setFieldValue('name', getUserName(user), false)
      if (values.phone === '') setFieldValue('phone', user.phone, false)
      hasUpdatedUser.current =  true
    }
  }, [user, values, dirty, hasUpdatedUser, setFieldValue])

  // Trigger events to update the map
  useEffect(() => {
    if (values.departure?.address && values.departure !== prevDeparture ||
      values.destination?.address && values.destination !== prevDestination) {

      const locations = [
        values.departure?.address ? values.departure : null,
      ]
      let active = null

      // If departure changed
      if (values.departure?.address && values.departure !== prevDeparture) {
        console.log('=== departure changed')
        const info = values.departure?.locationInformation?.filter(item => item.tag === 'INFO').map(item => item.message)
        console.log(info)
        setFieldValue('originInfo', info, false)
        active = 0
      }
      // Destination changed
      else if (values.destination?.address && values.destination !== prevDestination) {
        console.log('=== destination changed')
        locations.push(values.destination)
        active = 1
      }
      emitCustomEvent('LOCATION_CHANGE', { locations, active })
    }
  }, [values.departure, values.destination])

  return null
}

export default PackageModule

