import classnames from 'classnames'
import { useFormikContext } from 'formik'
import * as styles from './index.module.css'
import { useTranslation } from 'react-i18next'

export default function Notifications() {
  const { t } = useTranslation()
  const { values } = useFormikContext()
  if (!values.originInfo || values.originInfo.length === 0) return null
  // const values = {
  //   originInfo: [
  //     'ARLANDA TERMINAL 5 Förare möter dig i ankomsthallen med en namnskylt, bilen kallas fram då ditt flyg landar. Viss väntetid ingår: Inrikesflyg 20 minuter. Utrikesflyg 30 minuter. Efter det debiteras väntetaxa. Arlandas avgifter inkluderas: Terminalavgift 45 kr. Bokningsavgift 28 kr. Vid storbil klimatavgift på 64 kr.',
  //     // 'Vi bevakar din flight och möter dig med skylt i ankomsthallen. Bil  bokas när planet har landat. I det fastapriset ingår 10 min väntetid på inrikes- och utrikes flyg,  väntetid därutöver debiteras. Bromma tar dels ut en terminalavgift på 22 kr samt en förbokningsavgift på 28 kr Vid bokning av storbil tillkommer även en miljöavgift på 64 kr. I appen och på webben är avgifterna inkluderade i det fasta priset.'
  //   ]
  // }

  const readMore = message => {
    APP.trigger(
      APP.events.SHOW_MODAL,
      message, [
        { title: COPY.booking.button_ok },
      ]
    )
  }

  return (
    <ul className={styles.wrapper}> 
      {values.originInfo.map((item, index) => {
        return (
          <li key={index} className={classnames(styles.item, 'notifications')}>
            <span className={styles.text}>{item}</span>
            <button className={styles.button} onClick={() => readMore(item)}>{t('button-read-more')}</button>
          </li>
        )
      })}
    </ul>
  )
}

