.wrapper {
  background: var(--color-yellow);
  padding: 0 var(--padding);

  @media (--lg) {
    border-top-left-radius: var(--border-radius-large);
    border-top-right-radius: var(--border-radius-large);
  }
}

.item {
  display: flex;
  padding: calc(var(--spacing) * 2) 0 calc(var(--spacing) * 2) 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_652_870)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 14.4333C11.553 14.4333 14.4333 11.553 14.4333 8C14.4333 4.44697 11.553 1.56667 8 1.56667C4.44697 1.56667 1.56667 4.44697 1.56667 8C1.56667 11.553 4.44697 14.4333 8 14.4333ZM8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z' fill='black'/%3E%3Crect x='7.19995' y='4' width='1.6' height='1.6' rx='0.8' fill='black'/%3E%3Crect x='7.46655' y='6.66669' width='1.06667' height='5.33333' rx='0.213333' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_652_870'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center left;
}

.item + .item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.text, .button {
  color: black;
  font-size: 13px;
  line-height: 1em;
  white-space: nowrap;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.button {
  margin-left: var(--spacing);
  text-decoration: underline;
}
