import { useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'
import { fetchEta, fetchPriceEstimate } from '~/lib/api'
import i18next from '~/i18n'

const getNextTimeToRun = estimate => {
  const seconds = estimate?.prices?.[0]?.expirationTime
  return seconds ? (seconds - 60) * 1000 : false
}


/**
 * Component that fetches data depending on the Formik 
 * values for example ETA and price estimates.
 */
export default function Fetcher() {
  const etaInterval = useRef()
  const estimateTimeout = useRef()

  const { values, setFieldValue, setFieldError } = useFormikContext()

  const checkEta =() => {
    fetchEta(values.departure.address.position)
      .then((response) => {
        console.log('ETA:', response)
        setFieldValue('eta', response)
      })
      .catch((error) => {
        console.log(error.response)
        if (error?.response?.error === 'WAIT_TIME_NOT_AVAILABLE') {
          clearInterval(etaInterval.current)
          if (values.eta) setFieldValue('eta', '', false)
        }
      })
  }

  const estimatePrice = () => {
    setFieldValue('priceIsLoading', true, false)
    fetchPriceEstimate(values)
      .then((response) => {
        console.log('Estimate:', response)
        setFieldValue('priceEstimate', response, false)
        setFieldValue('priceToken', response.priceToken, false)
        const ms = getNextTimeToRun(response)
        if (ms) {
          console.log(`Next run in ${ms} milliseconds`)
          estimateTimeout.current = setTimeout(estimatePrice, ms)
        }
      })
      .catch((error) => {
        console.log(error.response)
        if (error?.response?.error === 'FIXED_PRICE_FOR_DESTINATION_NOT_FOUND' 
          || error?.response?.error === 'FIXED_PRICE_FOR_ORIGIN_NOT_FOUND') {
          const message = i18next.language === 'sv' ? 'Tyvärr ej tillgängligt' : 'Not available'
          setFieldError('fixedPrice', message) // TODO: This error should be fetched from the JSON
          setFieldValue('fixedPrice', false, false)
        }
        else {
          setFieldError('fixedPrice', error?.response?.message)
          setFieldValue('fixedPrice', false, false)
        }
      })
      .finally(() => {
        setFieldValue('priceIsLoading', false, false)
      })
  }

  useEffect(() => {
    if (values.pickupTime === '' && values.departure?.address?.position && values.serviceType !== 'delivery') {
      etaInterval.current = setInterval(checkEta, 1000 * 60)
      checkEta()
    }
    else {
      clearInterval(etaInterval.current)
      if (values.eta) setFieldValue('eta', '', false)
    }
    return () => {
      clearInterval(etaInterval.current)
    }
  }, [values.pickupTime, values.departure, values.serviceType])


  useEffect(() => {
    if (values.fixedPrice && values.departure?.address && values.destination?.address) {
      if (isNaN(values.bookingId)) {
        estimatePrice()
      }
    }
    else {
      clearTimeout(estimateTimeout.current)
      if (values.priceEstimate) {
        setFieldValue('priceEstimate', '', false)
        setFieldValue('priceToken', '', false)
      }
    }
    return () => {
      clearTimeout(estimateTimeout.current)
    }
  }, [values.departure, values.destination, values.intermediateStopLocations, values.pickupTime, values.fixedPrice, values.vouchers])


  useEffect(() => {
    if (!isNaN(values.bookingId)) {
      clearInterval(etaInterval.current)
      clearTimeout(estimateTimeout.current)
    }
  }, [values.bookingId])

  return null
}
