import { formatReadableDate } from '~/utils/format'
import i18next from '~/i18n'

export const getVoucherValue = voucher => {
  if (voucher.valueType == 'AMOUNT') {
    return `${voucher.value}kr`
  }
  else if (voucher.valueType == 'PERCENTAGE') {
    return `${voucher.value}%`
  }
  else if (voucher.valueType == 'OPEN_AMOUNT') {
    return i18next.t('free-trip')
  }
}

export const getCarTypes = () => [
  { value: 'taxi', title: i18next.t('cartype-taxi'), info: i18next.t('num-persons-4') },
  { value: 'largeCar', title: i18next.t('cartype-large-car'), info: i18next.t('num-persons-7') },
  { value: 'business', title: i18next.t('cartype-business'), info: i18next.t('num-persons-3') },
  { value: 'delivery', title: 'Bud', info: '' },
]

export const getCarType = serviceType => {
  return getCarTypes().find(c => c.value === serviceType)
}

export const hasFixedPrice = (priceComponents) => {
  if (priceComponents === undefined) return false
  return priceComponents.findIndex(c => c.type === 'fixedPrice') !== -1
}

export const getPriceFromEstimate = (prices, serviceType) => {
  return prices.find(p => p.serviceType === serviceType)?.finalPrice
    // ?.priceComponents.find(c => c.type === 'fixedPrice').amount
}

export const filterOptions = options => {
  if (options === undefined) return []
  return options.filter(value => ['pet', 'stationWagon', 'boosterSeat1', 'boosterSeat2'].includes(value))
}

export const getOptionText = value => {
  if (value === 'pet') return i18next.t('label-pet-option')
  if (value === 'stationWagon') return i18next.t('label-stationwagon-option')
  if (value === 'boosterSeat1') return i18next.t('label-booster-1-option')
  if (value === 'boosterSeat2') return i18next.t('label-booster-2-option')
}

export const getReceiptStatusText = booking => {
  switch (booking.receiptStatus) {
    // 100	AVAILABLE
    // 200	PENDING
    case '200':
      return i18next.t('info-receipt-pending')
    // 300	NO_RECEIPT_CANCELLED_ORDER (Cancelled order without upfront payment)
    // 400	NO_RECEIPT_FAILED_ORDER
    // 500	NO_RECEIPT (Since order is not paid by registered credit cards)
    // 600	NO_RECEIPT_TOO_OLD_ORDER
    // 700	NO_RECEIPT_LOST_BOM
    case '300':
    case '400':
    case '500':
    case '600':
    case '700':
      return i18next.t('info-receipt-not-available')
    // 800	NO_RECEIPT_REFUNDED_ORDER
    case '800':
      return i18next.t('info-receipt-refunded')
  }
}

export const getPackageText = value => {
  // Different copy depending on count
  const key = 'type-' + value.packageType
  return i18next.t(key, { count: value.count })
}

export const getTaxiAndEta = booking => {
  if (booking.bookingStatus.vehiclePlate !== undefined && booking.bookingStatus.eta) {
    return i18next.t('title-car-and-eta', { taxi: booking.bookingStatus.vehiclePlate, eta: formatReadableDate(new Date(booking.bookingStatus.eta)) })
  }
  return null
}

export const hasTaxiAndEta = booking => {
  return [120, 310, 320].includes(booking?.bookingStatus?.statusCode) && booking?.bookingStatus?.vehiclePlate !== undefined && booking?.bookingStatus?.eta
}

export const allowCancel = booking => {
  return [120, 310, 320].includes(booking?.bookingStatus?.statusCode)
}

export const hasEnded = booking => {
  return [220, 510, 610, 620].includes(booking?.bookingStatus?.statusCode)
}

export const getStatusText = booking => {
  // TODO: This should be replaced
  const COPY = window.COPY

  // 120 Confirmed
  if (booking.bookingStatus.statusCode == 120) {
    return booking.bookingType === 'PRE_BOOKING' ? i18next.t('status-pre-booked') : i18next.t('status-searching')
  }
  // 220 Cancelled
  if (booking.bookingStatus.statusCode == 220) return COPY.booking.status_cancelled

  // 310 Dispatched
  if (booking.bookingStatus.statusCode == 310) return COPY.booking.status_dispatched

  // 320 AtPickup
  if (booking.bookingStatus.statusCode == 320) return COPY.booking.status_at_pickup

  // 410 EnRoute
  if (booking.bookingStatus.statusCode == 410) return COPY.booking.status_en_route

  // 510 Done
  if (booking.bookingStatus.statusCode == 510) return COPY.booking.status_done

  // 610 Terminated
  if (booking.bookingStatus.statusCode == 610) return COPY.booking.status_cancelled

  // 620 TerminatedNoShow
  if (booking.bookingStatus.statusCode == 620) return COPY.booking.status_no_show
}

export const getStatusClassName = booking => {
  // 120 Confirmed
  if (booking.bookingStatus.statusCode == 120) {
    return booking.bookingType === 'PRE_BOOKING' ? 'prebooked' : 'searching'
  }
  // 220 Cancelled / 610 Terminated / 620 TerminatedNoShow
  if (booking.bookingStatus.statusCode == 220 || booking.bookingStatus.statusCode == 610 || booking.bookingStatus.statusCode == 620) return 'cancelled'

  // 310 Dispatched
  if (booking.bookingStatus.statusCode == 310) return 'arriving'

  // 320 AtPickup / 410 EnRoute
  if (booking.bookingStatus.statusCode == 320 || booking.bookingStatus.statusCode == 410) return 'in-car'

  // 510 Done
  if (booking.bookingStatus.statusCode == 510) return 'finished'
}
